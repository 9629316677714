/**
 * test
 */

export const environment = {
  production: true,
  REDIRECTION_URI: "https://www.biatrhdigital.com",
  KEYCLOAK_ADMIN_CONFIG:{
      URL: "https://www.biatrhdigital.com/keycloak",
      REALM: "Webiat",
      CLIENT_ID: "webiat-agent-web-client"
  }
};

export const REQUESTS_BASE_URL = 'https://www.biatrhdigital.com';